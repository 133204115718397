import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const About = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const hero = {
    title: "We're true product partners.",
    subtitle:
      'When you partner with Superfresh we deliver the goods for success.',
    img: '/about/hero.jpg',
    page: 'Contact Us',
  }

  return (
    <Layout location={location} title={siteTitle} hero={hero}>
      <SEO title="About" />
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
